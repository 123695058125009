import React, { useState, useEffect } from "react";
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter, MDBModalBody } from 'mdbreact';
import { MDBTabContent, MDBNav } from "mdbreact";
import ModalHeaderItem from "./ModalHeaderItem";
import ModalTabItem from "./ModalTabItem";
import { userSettings } from "../../user-settings";
import { filterDisplayedItems, findFirstItem } from "../../helpers/helpers";


const Modal = ({ isOpen, setIsOpen, articleDetails, showArticles }) => {
    const [activeItem, setActiveItem] = useState(null);

    useEffect(() => {
        setActiveItem(findFirstItem(userSettings.details, "label"));
    }, []);

    const toggle = tab => {
        if (activeItem !== tab) {
            setActiveItem(tab)
        };
    };

    const eventsItems = ["type", "state"];
    const messagesItems = ["id", "type", "created"];


    return (
        <MDBModal isOpen={isOpen} size="lg" toggle={() => setIsOpen(!isOpen)}>
            <MDBModalHeader className="special_color mb-4" toggle={() => setIsOpen(false)}>
                <MDBNav tabs>
                    {
                        !showArticles &&
                        filterDisplayedItems(userSettings.details)
                            .filter(item => item.label !== "Messages" || articleDetails?.messages?.length > 0)
                            .map(item => {
                                return (<ModalHeaderItem
                                    key={item.label}
                                    activeItem={activeItem}
                                    itemTitle={item.label}
                                    toggle={toggle}
                                />)
                            })
                    }
                </MDBNav>
            </MDBModalHeader>

            <MDBTabContent activeItem={activeItem}>
                {
                    showArticles ?
                        <MDBModalBody>
                            {articleDetails ?
                                articleDetails.map(item => {
                                    return item
                                })
                                    .join(', ')
                                :
                                "-"
                            }
                        </MDBModalBody>
                        :
                        <>
                            <ModalTabItem
                                tabId={"Metadata"}
                                data={articleDetails?.article}
                            />
                            <ModalTabItem
                                tabId={"Events"}
                                data={articleDetails?.events}
                                headerItems={eventsItems}
                                showInline
                            />
                            <ModalTabItem
                                tabId={"Messages"}
                                data={articleDetails?.messages}
                                headerItems={messagesItems}
                            />
                            <ModalTabItem
                                tabId={"Affiliations"}
                                data={articleDetails?.article}
                                headerItems={eventsItems}
                            />
                        </>
                }
            </MDBTabContent>

            <MDBModalFooter>
                <MDBBtn color="special_color" className="special_color text-white"
                    onClick={() => setIsOpen(false)}>
                    Close
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};


export default Modal;

