import React from "react";
import AsyncSelect from 'react-select/async';


const AsyncInput = ({ loadRorOptions, setValue, placeholder }) => {
    return (
        <AsyncSelect
            loadOptions={loadRorOptions}
            onChange={value => setValue(value.value)}
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            className="async_field"
        />
    );
};


export default AsyncInput;

