import React from "react";


const FilterPanel = ({ selectOptions, setFilterValue }) => {

    const onInput = event => {
        setFilterValue(event.target.value);
    };


    return (
        <div className="d-flex align-items-baseline mr-5">
            <span className="no-wrap mr-2">Filter by: </span>
            <select className="browser-default custom-select filter_select" onChange={onInput}>
                {
                    selectOptions.map(item => (
                        <option key={item.id} value={item.issn}>{item.title}</option>
                    ))
                }
            </select>
        </div>
    );
};


export default FilterPanel;

