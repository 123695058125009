import "./ErrorMessage.css";


const ErrorMessage = () => (
  <div className="error_container">
    <h5 className="error_message my-5">
      Something went wrong, please try again
    </h5>
  </div>
);

export default ErrorMessage;