import React from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import logo from "../images/hindawi/hindawi_logo.svg";


const Navbar = () => {

  return (
    <MDBNavbar color="special_color_light px-5" dark expand="md">
      <MDBNavbarBrand>
        <div className="logotype_navbar">
          <img src={logo} alt="logotype" className="logotype-image"></img>
        </div>
      </MDBNavbarBrand>
    </MDBNavbar>
  );
};


export default Navbar;