import { axiosInstance } from "../helpers/axiosSetup";
import { token } from "../constants";


export async function logIn(value) {
    return await axiosInstance
        .post('hindawi/authorize', value)
        .catch(error => console.log(error))
};

export const logOut = () => {
    localStorage.removeItem(token);
    window.location.reload();
};