import React from "react";
import { withFormik } from 'formik';
import { token } from "../../constants";
import "./Login.css";
import { MDBContainer } from 'mdbreact';
import LoginForm from "../../components/LoginForm";
import Payoff from '../../components/Payoff';
import { logIn } from "../../helpers/auth";


const Login = ({ handleSubmit }) => {

    return (
        <>
            <Payoff />

            <MDBContainer className="form_wrapper d-flex justify-content-center">
                <LoginForm handleSubmit={handleSubmit} />
            </MDBContainer>
        </>
    );
};


const mapPropsToValues = () => ({
    email: "",
    password: "",
});

const handleSubmit = (value, { props }) => {
    logIn(value)
        .then(res => {
            localStorage.setItem(token, res.data.token);
            props.history.push("/")
        })
        .catch(error => error)
};


export default withFormik({ handleSubmit, mapPropsToValues })(Login);