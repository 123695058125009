import React from "react";
import { useTable } from 'react-table';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import "./Table.css";
import sort from '../../images/sort-arrows.svg';
import sortUp from '../../images/sort-up.svg';
import sortDown from '../../images/sort-down.svg';


const Table = ({ data, columns, sortBy, sortDir, setSelectedMessage, setSortBy, setSortDir, param = "id" }) => {
    const tableInstance = useTable({ columns, data });

    const handleReadMessage = row => {
        const messageId = row && row.original ? row.original[param] : null;
        setSelectedMessage(messageId);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    const columnValues = ['manuscript_id', 'doi', 'journal', 'manuscript_title', 'lastevent'];

    const sortData = accessor => {
        if (columnValues.includes(accessor)) {
            const sortType = sortDir === "asc" ? "desc" : "asc";

            switch (accessor) {
                case "manuscript_id":
                    setSortBy("manuscript_id");
                    break;
                case "doi":
                    setSortBy("doi");
                    break;
                case "journal":
                    setSortBy("journal");
                    break;
                case "manuscript_title":
                    setSortBy("manuscript_title");
                    break;
                case "lastevent":
                    return
                default: break;
            }
            setSortDir(sortType);
        };
    };


    return (
        <MDBTable hover {...getTableProps()} className="shadow table">
            <MDBTableHead color="special_color" textWhite className="table">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}
                                onClick={() => sortData(column.id)}>
                                <span className='header-cell-wrapper'>
                                    {
                                        columnValues.includes(column.id) && column.id !== "lastevent" ?
                                            column.id === sortBy
                                                ?
                                                <img src={sortDir === "desc" ? sortUp : sortDown} className='sort_icons' alt="sort icon" />
                                                :
                                                <img src={sort} className='sort_icons' alt="sort icon" />
                                            :
                                            null
                                    }
                                    {column.render('Header')}
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </MDBTableHead>

            <MDBTableBody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}
                            onClick={() => handleReadMessage(row)}
                            className="table_row">
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </MDBTableBody>
        </MDBTable>
    );
};


export default Table;

