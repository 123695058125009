import React from "react";
import TextInput from "./TextInput";
import { Field } from 'formik';
import { MDBBtn } from 'mdbreact';
import "../App.css";


const LoginForm = ({ handleSubmit }) => {

    return (
        <form className="col-10 col-md-4" onSubmit={handleSubmit}>
            <p className="h4 text-center mb-5">Log in</p>
            <Field component={TextInput} name="email" label="Email" type="text" />
            <Field component={TextInput} name="password" label="Password" type="password" />

            <div className="text-center mt-4">
                <MDBBtn color="special_color" className="special_color text-white" type="submit">Login</MDBBtn>
            </div>
        </form>
    );
};


export default LoginForm;

