import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBBtn } from "mdbreact";
import { logOut } from "../helpers/auth";
import { userSettings } from "../user-settings";
import { filterDisplayedItems } from "../helpers/helpers";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => {
    setIsOpen(open => !open);
  };


  return (
    <MDBNavbar color="special_color_light px-5" light expand="md">
      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
        <MDBNavbarNav left>
          {
            filterDisplayedItems(userSettings.navbar).map(item => {
              return (
                <MDBNavItem key={item.label}>
                  <Link className="nav-link mr-3 font-weight-bolder" to={item.path}>{item.label}</Link >
                </MDBNavItem>
              )
            })
          }
        </MDBNavbarNav>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBBtn onClick={logOut} color="special_color" className="special_color text-white" size="sm">Log Out</MDBBtn>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};


export default Navbar;