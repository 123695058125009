import React, { useEffect, useState } from 'react';
import "./Modal.css";
import Affiliations from '../Affiliations';
import { filterInstitutions, parseData } from '../../helpers/helpers';
import SimpleRenderList from '../SimpleRenderList';
import Accordion from '../accordion/Accordion';
import dayjs from "dayjs";


const ModalContent = ({ data, headerItems, showInline, tabId }) => {
  const [content, setContent] = useState([]);

  useEffect(() => {
    if (data) {
      setContent(data);
    }
  }, [data]);


  const InfoSection = ({ title, info }) => {
    return (
      <div className="mt-3">
        <div className="details_header_secondary">{title}: </div>
        <span className="mr-3">{info}</span>
      </div>
    );
  };

  const ArticleInfo = ({ article }) => {
    const [showInfo, setShowInfo] = useState(false);
    const doi = article?.article?.doi || "-";
    const manuscriptId = article?.article?.manuscript?.id || "-";
    const title = article?.article?.title || "-";
    const submission = article?.article?.manuscript?.dates?.submission || "-";
    const acceptance = article?.article?.manuscript?.dates?.acceptance || "-";
    const publication = article?.article?.manuscript?.dates?.publication || "-";
    const authors = article?.authors || [];
    const correspondingAuthor = authors.filter(item => item.isCorrespondingAuthor);

    const validateDate = (date, format = 'YYYY-MM-DD') => {
      const dateIsValid = dayjs(date, format).format(format) === date;
      return dateIsValid ? date : '-';
    };

    const FormattedJson = data => {
      return (
        <pre className="modal-pre-text mt-5">{JSON.stringify(data, null, 4)}</pre>
      );
    };


    return (
      <>
        <>
          <InfoSection title="Doi" info={doi} />
          <InfoSection title="ManuscriptId" info={manuscriptId} />
          <InfoSection title="Title" info={title} />
          <InfoSection title="Submission" info={validateDate(submission)} />
          <InfoSection title="Acceptance" info={validateDate(acceptance)} />
          <InfoSection title="Publication" info={validateDate(publication)} />

          <div className="mt-3">
            <div className="details_header_secondary">Author(s): </div>
            {authors ?
              authors.map(item => {
                return `${item.firstName} ${item.lastName}`
              })
                .join(', ')
              :
              "-"
            }
          </div>

          <div className="mt-3">
            <div className="details_header_secondary">Corresponding author(s): </div>
            {correspondingAuthor ?
              correspondingAuthor.map(item => {
                return `${item.firstName} ${item.lastName}`
              })
                .join(', ')
              :
              "-"
            }
          </div>

          <div className="mt-3">
            <div className="details_header_secondary">Institutions: </div>
            {correspondingAuthor ?
              filterInstitutions(correspondingAuthor).map(item => item.name).join(', ')
              :
              "-"
            }
          </div>
        </>
        {
          showInfo &&
          <FormattedJson data={parseData(content)} />
        }
        <div className="mt-4" onClick={() => setShowInfo(prev => !prev)}>{showInfo ? 'Hide JSON' : 'Show JSON'}</div>
      </>
    );
  };


  return (
    tabId === "Metadata" && data ?
      <ArticleInfo article={data} />
      :
      tabId === "Events" && data ?
        <Accordion
          items={Array.isArray(content) ? content : [content]}
          headerItems={headerItems}
          showInline={showInline}
        />
        :
        tabId === "Affiliations" && data ?
          <Affiliations article={data} />
          :
          tabId === "Messages" && data ?
            <Accordion
              items={content}
              headerItems={headerItems}
              showInline={showInline}
            />
            :
            <SimpleRenderList items={content} />
  );
};


export default ModalContent;