import React from "react";
import { MDBNavItem, MDBNavLink } from "mdbreact";


const ModalHeaderItem = ({ activeItem, toggle, itemTitle }) => {

    return (
        <MDBNavItem className={activeItem === itemTitle ? "focused" : ""}>
            <MDBNavLink to="#"
                onClick={() => toggle(itemTitle)} role="tab">
                <h4 className="my-0 mx-2 font-weight-bolder">{itemTitle}</h4>
            </MDBNavLink>
        </MDBNavItem>
    );
};


export default ModalHeaderItem;

