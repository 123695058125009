import React from "react";
import { MDBIcon, MDBBtn, MDBFormInline } from "mdbreact";


const SearchPanel = ({ onSearch, searchValue, setSearchValue }) => {

    const onSubmit = event => {
        event.preventDefault();
        onSearch();
    };

    const onInput = event => {
        setSearchValue(event.target.value);
    };


    return (
        <MDBFormInline className="md-form" onSubmit={onSubmit}>
            <input value={searchValue} onChange={onInput} className="form-control mb-0" type="text" placeholder="Search" />

            <MDBBtn color="special_color" size="sm" className="ml-3 py-2 px-4 special_color text-white" type="submit">
                <MDBIcon size="lg" icon="search" />
            </MDBBtn>
        </MDBFormInline>
    );
};


export default SearchPanel;

