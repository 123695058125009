import { token } from "../constants";

export const handleErrors = ({ response }) => {
  if (response.status === 401) {
    localStorage.removeItem(token);
    window.location.reload(true);
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};