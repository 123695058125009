import React, { useState, useEffect } from "react";
import Table from "../../components/table/Table";
import Loader from "../../components/Loader";
import SearchPanel from "../../components/table/SearchPanel";
import FilterPanel from "../../components/table/FilterPanel";
import Pagination from "../../components/pagination/Pagination";
import { getArticles, getArticleDetails, getJournals } from "../../helpers/articles";
import Modal from "../../components/modal/Modal";
import { allFilterOption, itemsPerPage } from "../../constants";
import { MDBCol, MDBRow } from 'mdbreact';
import Card from "../../components/Card";
import BarChart from "../../components/charts/BarChart";
import { getWeeklyArticles } from "../../helpers/statistics";
import ErrorMessage from "../../components/error/ErrorMessage";


const Pipeline = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [searchValue, setSearchValue] = useState("");
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [weeklyArticles, setWeeklyArticles] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [error, setError] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
            },
            {
                Header: 'DOI',
                accessor: 'doi',
            },
            {
                Header: 'journal title',
                accessor: 'journal',
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title'
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
            }
        ], []
    );

    const addAllFilterOption = arr => {
        arr.unshift(allFilterOption);
        return arr;
    };

    useEffect(() => {
        getWeeklyArticles()
            .then(res => setWeeklyArticles(res.data));

        getJournals()
            .then(res => setFilterOptions(addAllFilterOption(res.data)));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            setLoading(true)
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setLoading(false))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "production", filterValue)
            .then(res => {
                if (isMounted) {
                    setArticles(res.data.articles);
                    setPageCount(Math.ceil(res.data.total / itemsPerPage));
                    setLoading(false);
                };
            })
            .catch(error => setError(true))
        return () => { isMounted = false };
    }, [offset, sortBy, sortDir, filterValue]);

    const handlePageClick = data => {
        const selectedPage = data.selected;
        const offset = selectedPage * itemsPerPage;
        setCurrentPage(selectedPage);
        setOffset(offset + 1);
    };

    const onSearch = () => {
        let isMounted = true;
        setLoading(true);

        getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "", filterValue)
            .then(res => {
                if (isMounted) {
                    setOffset(1);
                    setCurrentPage(0);
                    setArticles(res.data.articles);
                    setPageCount(Math.ceil(res.data.total / itemsPerPage));
                    setLoading(false);
                };
            })
            .catch(error => setError(true))
        return () => { isMounted = false };
    };


    return (
        <div className="container">
            <MDBRow className="mt-5" center>
                <MDBCol md='6' className="mb-5 mt-4">
                    <Card
                        title="New articles per week:"
                        content={<BarChart data={weeklyArticles} period="week" />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="d-flex align-items-baseline justify-content-between">
                <Pagination
                    handlePageClick={handlePageClick}
                    pageCount={pageCount}
                    articles={articles}
                    currentPage={currentPage}
                />

                <div className="d-flex align-items-baseline">
                    <FilterPanel
                        selectOptions={filterOptions}
                        setFilterValue={setFilterValue}
                    />

                    <SearchPanel
                        onSearch={onSearch}
                        setSearchValue={setSearchValue}
                        searchValue={searchValue}
                    />
                </div>
            </div>

            {
                loading ?
                    <Loader />
                    :
                    error ?
                        <ErrorMessage />
                        :
                        articles.length ?
                            <>
                                <Modal
                                    isOpen={isOpen}
                                    setIsOpen={setIsOpen}
                                    articleDetails={articleDetails}
                                />

                                <Table
                                    data={articles}
                                    columns={columns}
                                    sortBy={sortBy}
                                    sortDir={sortDir}
                                    setSelectedMessage={setSelectedMessage}
                                    setSortBy={setSortBy}
                                    setSortDir={setSortDir}
                                />
                            </>
                            :
                            <h6 className="text-center my-4">No articles</h6>
            }
        </div>
    );
};


export default Pipeline;