import React from "react";
import { Bar } from 'react-chartjs-2';
import { colorsPalette } from "../../constants";


const BarChart = ({ data, period, value }) => {
    const barChartData = {
        labels: data.map(item => item[period]),
        datasets: [
            {
                data: data.map(item => item[value] || item.count),
                backgroundColor: colorsPalette,
            },
        ],
    };

    const options = {
        scales: {
            x: { title: { display: true, text: period } },
            y: { title: { display: true, text: 'counts' } }
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };


    return (
        <div className="m-3">
            <Bar data={barChartData} options={options} />
        </div>
    );
};


export default BarChart;