import React from "react";
import { MDBFooter } from "mdbreact";


const Footer = () => {
    return (
        <MDBFooter className="font-small mt-4 footer">
            <div className="logotype-footer-title my-3">Privacy Policy | Terms of Service | Responsible Disclosure Policy | Cookie Policy | Copyright | Modern slavery statement</div>
        </MDBFooter>
    );
};


export default Footer;

