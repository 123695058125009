export const token = `${process.env.REACT_APP_TOKEN}`;
export const URL = `${process.env.REACT_APP_API_BASE_URL}`;

export const itemsPerPage = 10;
export const colorsPalette = [
    "#dcedc8", "#c5e1a5", "#aed581", "#9ccc65", "#8bc34a", "#7cb342", "#689f38", "#558b2f", "#33691e", 
];

export const allFilterOption = {
    "issn": "",
    "title": "All journals",
    "id": "All journals"
};
